@use "../../../styles/mixins";
@import "../../../styles/colors";

@mixin navOption {
    font-family: "Inter", sans-serif;
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
}

@mixin navButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem; //get from variable
    height: 4rem; //get from variable
    border: none;
    background-color: transparent;
    color: $lms-black;
}


.a {
    @include mixins.inter;
    @include navOption;
    color: $lms-black;
    position: relative;
    //transition: 0.3s all;
    display: flex;
    align-items: center;


    &:hover {
        color: $lms-primary-dark;
        //font-weight: 800; //get from variable
    }
}

.button {
    @include navButton;
    width: auto;
    @include navOption;

    .chevron {
        transition: 0.3s all;
    }

    &:hover {
        color: $lms-primary-dark;
        //font-weight: 800; //get from variable
    }
    &.active,
    &:active {
        color: $lms-primary-dark;
        

        .chevron {
            transform: rotate(-180deg);
        }
    }



}
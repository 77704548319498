@use "../../../styles/mixins";
@import "../../../styles/colors";

.searchToolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    :first-child {
      flex-grow: 1;
      position: relative;
    }
}

.searchForm {
    flex: 1;

    .formGroup {
        width: calc(100% - 1rem);
        margin: 0;
    }
    .inputGroup {
        width: 100%;
        border: thin solid #ced4da;
        border-radius: 0.5rem;
    }
    .inputGroupAppend {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;

    }
    .formControl {
        @include mixins.formControl;
        border: none;
        &:focus {
            box-shadow: none;
        }   
    }
}

.closeSearchToolbar {
    flex-grow: 0;
    flex-shrink: 0;
}

.customInput {
  font-size: 1rem;
  font-weight: 400;
  background: $lms-white;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0.375rem 3rem 0.375rem 0.75rem;

  &:focus {
      color: $lms-black;
      border-color: $lms-primary-dark;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba($lms-primary-light, 0.25);
  }

  & + div {
    position: absolute;
    width: 100%;

    ul {
      background-color: $lms-white;
      width: 100%;
      border: 1px solid $lms-primary-dark;
      border-radius: 0.5rem;

      li {
        display: flex;
        align-items: center;
        height: 3rem;
        padding: 0 0.75rem;
        font-size: 1rem;
        font-weight: 400;

        &:hover {
          cursor: pointer;
          background-color: $lms-gray-light;
        }
      }
    }
  }
}

.HeaderBrand {
    display: flex;
    align-items: center;
    .logo {
        height: 3rem; // get from variable
        margin-right: 0.5rem;
    }
    h1 {
        margin: 0;
        font-size: 2rem; // get from variable
        line-height: 2rem; // get from variable;
        white-space: nowrap
    }
    .onlyDesktop {
        display: none;
    }
}

@media (max-width: 640px) {
    .HeaderBrand {
        h1 {
            display: none;
        }
    }
}

@media screen and (min-width: 720px) {
    .HeaderBrand {
        .onlyMobile {
            display: none;
        }
        .onlyDesktop {
            display: block;
        }
    }
}
@import "../../styles/colors";

footer.footer {
    background-color: $lms-bg-gray;
    min-height: 10rem; // get from variable
    padding: 4rem 0;
    display: flex;
    justify-content: center;

    .layout {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        width: 100%;
        max-width: 1024px; // get from variable
        height: 100%;
        padding: 0 2rem;

        >section {
            padding-top: 1rem;
            margin: 0 1rem 0 0;

            &:first-child {
                grid-column: 1 / 3;
                margin-left: 0;
            }

            &:nth-child(2) {
                grid-column: 4;
            }

            &:nth-child(3) {
                grid-column: 5;
            }

            &:nth-child(4) {
                grid-column: 6;
                margin-right: 0;
            }

            h5 {
                font-size: 1rem; // get from variable
                font-weight: 700;
                margin-bottom: 2rem;
                color: $lms-primary-dark;
            }

            h6 {
                font-size: 1rem; // get from variable
                font-weight: 600;
                margin-bottom: 1rem;
                color: $lms-primary-dark;
            }

            article {
                margin-bottom: 2rem;
                a {
                    color: $lms-primary-dark;
                    text-decoration: underline;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                color: $lms-primary-dark;

                >li {
                    margin-bottom: 0.5rem;
                    >a {
                        text-decoration: underline;
                    }
                }
            }
        }

        .mark {
            padding-top: 0;

            >ul.social {
                margin-top: 1rem;
                display: flex;
                list-style: none;
                padding: 0 0 0 2rem;

                >li {
                    margin-right: 1rem;
                }
            }
        }

        .contact {
            >ul {
                >li {
                    >a {
                        
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .legalPolicies {
        a {
            color: $lms-primary-dark;
            text-decoration: none;
        }
    }
}


@media screen and (max-width: 960px) {
    footer.footer {

        .layout {
            display: flex;
            flex-direction: column;

            .mark {
                margin-bottom: 3rem;
            }

        }
    }
}
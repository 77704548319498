@import "../../../styles/colors";

.socialIcon {
    color: $lms-white;
    width: 2.5rem;
    height: 2.5rem;
    background-color: $lms-accent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    &:hover {
        color: $lms-white;
        // background-color: $lms-secondary-dark; //get from variable
        
    }
    &:focus {
        color: $lms-white;
        // background-color: darken($color: $lms-secondary-dark, $amount: 1) ; //get from variable
    }
}
@use "../../../styles/mixins";
@import "../../../styles/colors";

ul.level1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    >li {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        width: calc(33.3% - 1rem);

        @media screen and (min-width: 768px) {
            width: calc(12.5% - 1rem);
        }

        >header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0;

            >a, >p {
                text-transform: uppercase;
                font-size: 0.875rem;

                &:hover {
                    cursor: pointer;
                    color: $lms-primary-dark;
                }
            }

            .chevron {
                cursor: pointer;
            }
        }
    }
}

ul.level2 {

    >li {

        >a {
            text-transform: uppercase;
            font-size: 0.75rem;
        }
    }
}

@media screen and (max-width: 1024px) {
    ul.level1 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 576px) {

    ul.level1 {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 375px) {

    ul.level1 {
        grid-template-columns: 1fr;
    }

}
.suggestContainer {
    display: flex;
    max-width: 100%;
    justify-content: flex-start;

}

.suggestImageContainer {
    width: 24px;
    flex-grow: initial !important;
    margin-right: 8px;
}

.suggestImage {
    height: 24px;
    object-fit: cover;
    margin-right: 8px;
}

.autosuggest {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}
@import "../../../styles/colors";
.badge {
    border-radius: 0.5rem;
    background-color: $lms-primary-dark;
    color: #fff;
    min-width: 1rem;
    height: 1rem;
    text-align: center;
    font-size: 0.75rem;
    padding: 0.25rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: -0.5rem;
}
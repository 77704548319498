@use "../../styles/mixins";
@import "../../styles/colors";


.cookieConsent {
    background: $lms-white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    margin-bottom: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 1rem 2rem;
    box-shadow: 0 0 2rem 0.25rem rgb(0 0 0 / 20%);

    @supports (backdrop-filter: blur(0.5rem)) {
        backdrop-filter: blur(0.5rem) saturate(125%);
        background-color: rgba(255, 255, 255, 0.75);
    }
}
@use "../../../styles/mixins";
@import "../../../styles/colors";

.stbTag {
    @include mixins.p;
    color: $lms-gray-dark;
    background-color: $lms-gray-light;
    border-radius: 0.5rem;
    height: 2rem;
    padding: 0 0.5rem;
    margin: 0 0.5rem 0.5rem 0;
    line-height: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.6s background-color color;

    &:hover {
        background-color: $lms-primary-light;
        color: $lms-black;
    }
    .stbTagIcon {
        height: 1rem;
        margin-left: 0.5rem;
    }
}
@use "../../../styles/mixins";
@import "../../../styles/colors";

.discountWrapper {
    background-color: $lms-primary-dark;
    color: $lms-white;
    border-radius: 4px;
    display: inline-block;
    padding: 4px;
    position: absolute;
    right: 8px;
    font-weight: 600;
}
@use "../../styles/mixins";
@import "../../styles/colors";

.emptyResults {
    grid-area: results;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    >img {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    header {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 2rem;
        max-width: 60%;

        h1 {
            text-align: center;
            @include mixins.bebas-neue;
            @include mixins.h1;
            color: $lms-primary-dark;
        }

        p {
            text-align: center;
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 0;

            &:last-of-type {
                margin-bottom: 2rem;
            }
        }

    }


}
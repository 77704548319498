@use "../../../styles/mixins";
@import "../../../styles/colors";

.filter {
    margin-bottom: 2rem;

    .filterTitle {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li {
            color: $lms-gray-dark;
            font-weight: 400;
            font-size: 1rem;
            line-height: 2;
            transition: 0.6s color;
            cursor: pointer;
            //position: relative;

            &.selected {
                color: $lms-primary-light;
                &::after {
                    //position: absolute;
                    //right: -1.5rem;
                    content: "✔";
                    margin-left: 0.5rem;
                }
            }

            &:hover {
                color: $lms-primary-light;
            }
        }
    }
}
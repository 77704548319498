$lms-black: #1A1A1A;
$lms-gray: #666;
$lms-gray-dark: #666;
$lms-gray-light: #ddd;
$lms-white: #fff;

$lms-primary-light: rgba(35, 75, 151, .7);
$lms-primary-dark: rgba(35, 75, 151, 1);

$lms-secondary-light: rgba(54, 201, 241, .7);
$lms-secondary-dark: rgba(54, 201, 241, 1);

$lms-accent: #23EBBF;

$lms-bg-light: #FFF9F5;
$lms-bg-dark: #FEF2DD;

$lms-bg-gray: #F7F7F7;
